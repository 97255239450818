import React, { useEffect, useState } from "react";
import { Nav, Navbar, Form, Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectUser, logout } from "../../features/user/userSlice";
import { useHistory } from "react-router-dom";
import "../../css/navigation.css";
import { getIntial } from "../../util";
import FindClincs from "../clinics/findClincs";

function Navigation() {
  const [open, setopen] = useState(false);
  const user = useSelector(selectUser);
  const history = useHistory();

  const handleLogout = () => {
    localStorage.clear();
    history.push("/signin");
  };

  return (
    <Navbar className="app__navbar" variant="dark" expand="lg">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Brand href="/">Beautifully Korea</Navbar.Brand>
      <Form inline className="navbar__form">
        <FindClincs />
      </Form>
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav className="justify-content-end">
          <Nav.Link className="nav-link" href="/me">
            My Profile
          </Nav.Link>

          <Nav.Link className="nav-link" href="/edit-me">
            Edit Profile
          </Nav.Link>
          {localStorage.getItem("token") ? (
            <div className="menu">
              <Button onClick={handleLogout} variant="danger">
                Logout
              </Button>
              <div onClick={() => setopen(!open)} className="nav-avatar">
                {user?.photoUrl === "" || user?.photoUrl.type === undefined ? (
                  <strong className="initial">{user?.name.slice(0, 1)}</strong>
                ) : (
                  <div>
                    <img
                      className="nav-avatar"
                      src={user?.photoUrl}
                      alt={user?.name}
                    />
                  </div>
                )}
              </div>

              {open && (
                <ul className="menuContainer">
                  <li>
                    <Nav.Link
                      href={
                        user.type === "clinic"
                          ? `api/clinic/${user?.name}/${user?._id}`
                          : `api/user/${user?.name}/${user?._id}`
                      }
                    >
                      View Profile
                    </Nav.Link>
                  </li>
                  <li></li>
                </ul>
              )}
            </div>
          ) : (
            <>
              {user ? (
                <div className="menu">
                  <div onClick={() => setopen(!open)} className="nav__user">
                    {user.photoUrl === "" ? (
                      <strong className="nav__initial">
                        {getIntial(user?.name)}
                      </strong>
                    ) : (
                      <img
                        className="nav__avatar"
                        src={user?.photoUrl}
                        alt={user?.name}
                      />
                    )}
                  </div>
                  {open && (
                    <ul className="menuContainer">
                      <li>
                        {" "}
                        <Nav.Link
                          href={
                            user.account === "clinic"
                              ? `api/clinic/${user?.name}/${user?.id}`
                              : `api/user/${user?.name}/${user?.id}`
                          }
                        >
                          {" "}
                          View Profile
                        </Nav.Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Button onClick={handleLogout} variant="danger">
                          Logout
                        </Button>{" "}
                      </li>
                    </ul>
                  )}
                </div>
              ) : (
                <>
                  <Nav.Link className="nav-link" href="/signin">
                    Signin
                  </Nav.Link>
                  <Nav.Link className="nav-btn btn primary-btn" href="/signup">
                    Signup
                  </Nav.Link>
                </>
              )}
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navigation;
