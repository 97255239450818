import React from "react";
import UploadPhotos from "../components/photos/uploadPhotos";
import "../css/userprofile.css";

import { editUserProfile } from "../api";

export default function EditProfile() {
  return (
    <div>
      <UploadPhotos />

      <div className="edit_profile_container">
        <form className="edit_profile">
          <input value="interest" />
          <input value="music" />
          <input value="career" />
          <textarea value="about-me" />
          <button onClick={() => editUserProfile()}> make changes</button>
        </form>
      </div>
    </div>
  );
}
