import { useState, useEffect } from "react";
import { fetchAllPosts, addMessageToPost } from "../../api";

import "./posts.css";

const Posts = () => {
  const [state, setState] = useState([]);
  const [message, addMessage] = useState("");

  useEffect(() => {
    async function getPosts() {
      setState(await fetchAllPosts());
    }

    getPosts();
  }, []);

  const handleChange = (e) => {
    addMessage(e.target.value);
  };

  const handleSubmit = async (props) => {
    const sender = localStorage.getItem("name");

    const data = { messgae: message, sender: sender };
    await addMessageToPost(data);
  };

  return (
    <div>
      {state.map((posts) => (
        <ul>
          <li key={posts._id}>{posts.topic}</li>

          <u1>
            {posts.conversation.map((post) => (
              <li key={post._id} className="messages">
                <img className="avatar" src={post.photoUrl} alt={post.sender} />
                <div>{post.message}</div>
              </li>
            ))}
          </u1>
          <form onSubmit={handleSubmit}>
            <textarea type="text" onChange={handleChange}></textarea>
            <input type="submit" value="Submit" />
          </form>
        </ul>
      ))}
    </div>
  );
};

export default Posts;
