import { useState, useEffect } from "react";
import { fetchAds } from "../../api";

import { Card, ListGroup, ListGroupItem, CardText, CardBody, CardTitle, CardLink, CardImg } from "reactstrap";

import "./ads.css";
import { LikeOrDislike } from "../../features/likes";

const Ads = (data) => {
  const [state, setState] = useState([]);

  useEffect(() => {
    async function getPhotos() {
      setState(await fetchAds());
    }

    getPhotos();
  }, []);

  return (
    <div className="ad_container">
      {state.map((ad) => (
        <Card key={ad._id} style={{ width: "18rem", background: "lightblue" }}>
          <CardBody style={{ background: "#2d6b88" }}>
            <CardTitle style={{ background: "lightBlue" }}>{ad.name}</CardTitle>
            <CardImg variant="top" src={ad.imageUrl} />
            <br />
            <br />
            <CardText style={{ background: "lightBlue" }}>{ad.description}</CardText>
            <ListGroup>
              <ListGroupItem style={{ background: "lightBlue" }}>{ad.contact}</ListGroupItem>

              <ListGroupItem style={{ background: "lightBlue" }}>{ad.phoneNumber}</ListGroupItem>
              <ListGroupItem style={{ background: "lightBlue" }}>{ad.address}</ListGroupItem>
              <ListGroupItem style={{ background: "lightBlue" }}>{ad.workingHours}</ListGroupItem>
            </ListGroup>
          </CardBody>
          <CardBody>
            <CardLink style={{ background: "lightBlue" }} href={ad.siteUrl}>
              {" "}
              Visit {ad.name}{" "}
            </CardLink>
          </CardBody>
          <LikeOrDislike likes={ad.likes} dislikes={ad.dislikes} />
        </Card>
      ))}
    </div>
  );
};

export default Ads;
