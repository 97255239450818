export const getID = function () {
  return Math.random().toString(36).substr(2, 9);
};

export const trimString = (str, length) => {
  if (str.length > length) {
    return str.substr(0, length) + "...";
  }
  return str;
};

export const getIntial = (name) => {
  return name.slice(0, 1);
};

export const getImgSrc = () => {
  return `http://localhost:3000/consumerPhotos`;
};
