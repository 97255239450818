import React, { useState, useEffect } from "react";
import { fetchAds } from "../../api";

function FindClincs() {
  const [state, setState] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    async function getPhotos() {
      setState(await fetchAds());
    }

    getPhotos();
  }, []);

  const results = !searchTerm
    ? state
    : state.filter((ads) =>
        ads.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

  return (
    <div className="searchbar">
      <input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={handleChange}
      />
      {searchTerm.length > 2 ? <ul>{results.map((ad) => ad.name)}</ul> : null}
    </div>
  );
}

export default FindClincs;
