import React, { useState, useEffect } from "react";
import { fetchSingleUser } from "../../api";
import { Card, CardBody, CardTitle, CardLink, CardImg } from "reactstrap";
import "./friend.css";

function Friends() {
  const [state, setState] = useState([]);

  useEffect(() => {
    async function getFriends() {
      setState(await fetchSingleUser(localStorage.getItem("email")));
      console.log(localStorage.getItem("EMAIL"));
    }

    getFriends();
  }, []);

  return (
    <div className="col">
      <ul className="friends_list">
        {state.map((user) =>
          user.friends.map((friend) => (
            <li>
              <Card
                key={friend._id}
                style={{
                  width: "12rem",
                  listStyle: "none",
                  background: "lightBlue",
                }}
              >
                <CardBody style={{ background: "#2d6b88" }}>
                  <CardTitle style={{ background: "lightBlue" }}>
                    {friend.name}
                  </CardTitle>
                  <CardImg variant="top" src={friend.src} />
                  <CardLink
                    style={{ background: "lightBlue" }}
                    href={friend.profilePage}
                  ></CardLink>
                </CardBody>
              </Card>
            </li>
          ))
        )}
      </ul>
    </div>
  );
}

export default Friends;
