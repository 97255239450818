import axios from "axios";

//export const API_BASE_URL =
export const API_BASE_URL = "/api";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

export default axiosInstance;

// POST Requests

// Get Requests

//should we always search when possible for id when using mongo ???

//whats wrong here ? lolim tired
export const editUserProfile = async (data) => {
  let email = localStorage.getItem("email");
  //let email = localStorage.getItem('EMAIL')

  const formData = new FormData(data);

  await axiosInstance.post(`/profile/${email}`, formData);
};

// Get Requests working2
export const fetchUsers = async () => {
  const { data } = await axiosInstance.get("/users");
  return data;
};

export const allAccounts = async () => {
  const { data } = await axiosInstance.get("/accounts");
  return data;
};

//working3
export const fetchSingleUser = async (email) => {
  //let email = localStorage.getItem('id')

  const { data } = await axiosInstance.get(`/accounts/${email}`);
  return data;
};

//working 4
export const fetchClinics = async () => {
  const { data } = await axiosInstance.get("/clinics");
  return data;
};

export const fetchSingleClinic = async (clinicId) => {
  const { data } = await axiosInstance.get(`/accounts/${clinicId}`);

  return data;
};

export const fetchAds = async () => {
  const { data } = await axiosInstance.get(`/ads`);
  return data;
};

export const fetchSingleAd = async (adId) => {
  const { data } = await axiosInstance.get(`/ads/${adId}`);

  return data;
};

export const fetchAllPosts = async () => {
  const { data } = await axiosInstance.get(`/posts`);

  console.log(data);
  return data;
};

export const fetchSinglePhoto = async (photoId) => {
  const { data } = await axiosInstance.get(`/photos/:${photoId}`);

  console.log(data);
  return data;
};

//post requests
//working 1
export const uploadPhoto = async (data) => {
  const email = localStorage.getItem("email");

  const formData = new FormData();
  formData.append("photo", data);
  formData.append("email", email);
  await axiosInstance.post("/photos/upload", formData);
};

export const addFriend = async (data, friendName, friendEmail) => {
  const email = localStorage.getItem("email");
  const formData = new FormData();

  formData.append("name", friendName);
  formData.append("email", friendEmail);

  await axiosInstance.post(`/friends/${email}`, formData);
};

//needs work
export const addMessageToPost = async (data, props) => {
  const formData = new FormData();
  const { message, sender } = data;

  //what the prop again lol im tired

  formData.append("message", `${message}`);
  formData.append("sender", `${sender}`);
  console.log(formData);

  await axiosInstance.post(`/posts/${props}`, formData);
};
