import Signin from "../pages/Signin";
import Signup from "../pages/Signup";
import Home from "../pages/Home";
import UserProfile from "../pages/UserProfile";
import EditProfile from "../pages/EditProfile";

export const routes = [
  {
    name: "Signin",
    path: "/signin",
    Component: Signin,
    isLoggedIn: false,
  },
  {
    name: "Signup",
    path: "/signup",
    Component: Signup,
    isLoggedIn: false,
  },
  {
    name: "Home",
    path: "/",
    Component: Home,
    isLoggedIn: false,
    exact: true,
  },
  {
    name: "UserProfile",
    path: "/me",
    Component: UserProfile,
    isLoggedIn: false,
    exact: true,
  },

  {
    name: "EditProfile",
    path: "/edit-me",
    Component: EditProfile,
    isLoggedIn: false,
    exact: true,
  },
];
