import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    loading: true,
  },
  reducers: {
    setLoading: (state, action) => {
      console.log(action.payload);
      state.loading = action.payload;
    },
  },
});

export const { setLoading } = appSlice.actions;

export const selectLoading = (state) => state.app.loading;

export default appSlice.reducer;
