import React, { useState, useEffect } from "react";
import { CardBody, Card, CardTitle, CardImg, ListGroup } from "reactstrap";
import { fetchSingleUser } from "../api";

export default function UserProfile() {
  const [state, setState] = useState([]);

  useEffect(() => {
    async function getPhotos() {
      setState(await fetchSingleUser(localStorage.getItem("email")));
    }
    getPhotos();
  }, []);

  return (
    <div className="profile">
      <Card>
        {state.map((user) => (
          <CardBody>
            <CardTitle>{user.name}</CardTitle>
            <CardImg className="profile_pic" src={user.photoUrl}></CardImg>
          </CardBody>
        ))}

        <CardBody className="profile_info_container">
          {state.map((user) =>
            user.profile.map((profile) => (
              <ListGroup className="profile_info " key={profile._id}>
                <listGrounItem>Interest:{profile.interests}</listGrounItem>
                <listGrounItem>Music:{profile.music}</listGrounItem>
                <listGrounItem>Career:{profile.career}</listGrounItem>
                <listGrounItem>About Me: {profile.aboutMe}</listGrounItem>
              </ListGroup>
            )),
          )}
        </CardBody>
      </Card>
      <div>
        <br />
        <br />
        <h2>MY PHOTOS</h2>
        <ul className="my_photos_container">
          {state.map((user) =>
            user.photos.map((photo) => (
              <Card className="my_photos">
                <CardBody className="photo " key={photo._id}>
                  <CardImg
                    className="photo_image"
                    src={photo.src}
                    alt={photo.description}
                    style={{ height: "100px", width: "100px" }}
                  ></CardImg>
                </CardBody>
              </Card>
            )),
          )}
        </ul>
      </div>

      <br />
      <div>
        <h2>MY Friends</h2>
        <ul className="friends_list_container">
          {state.map((user) =>
            user.friends.map((friend) => (
              <Card className="friends_list">
                <CardBody className="friend " key={friend._id}>
                  <CardTitle>{friend.name}</CardTitle>
                  <CardImg
                    className="friend_image"
                    src={friend.src}
                    alt={friend.name}
                    style={{ height: "100px", width: "100px" }}
                  ></CardImg>
                </CardBody>
              </Card>
            )),
          )}
        </ul>
      </div>
    </div>
  );
}
