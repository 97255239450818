import axios from "axios";
import React, { useState } from "react";
import { Form, Button, InputGroup, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import "../css/signin.css";
import "../css/LandingPage.css";

// Modified to work with parts from original LoginInForm made by Isabella
function Signin({ history }) {
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [accountType, setAccountType] = useState(false);
  const [loading, setLoading] = useState(false);
  const { register, errors } = useForm();

  const handleAccount = () => {
    setAccountType(!accountType);
  };

  const handleChange = (event) => {
    event.persist();
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSignin = async (event) => {
    setLoading(true);

    const email = state.email;
    const password = state.password;
    const account = accountType ? "Clinic" : "Client";
    try {
      event.preventDefault();
      const data = { password: password, email: email, account: account };
      const res = await axios.post("/api/signin", data);
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("name", res.data.name);
      localStorage.setItem("account", data.account);
      localStorage.setItem("email", res.data.email);
      localStorage.setItem("id", res.data.id);

      toast.success("successfully logged in", {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push("/");
    } catch (err) {
      console.error(err);
      toast.error("sorry something went wrong , try again later", {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoading(false);
  };
  return (
    <div className="sign__container">
      <div className="welcome-text">
        Welcome to Korea, please log in to continue
      </div>

      <div className="signin">
        <Form className="signin__form" onSubmit={handleSignin}>
          <h4 className="text-center">
            <strong> Signin to your account </strong>
          </h4>
          <div className="mb-3 text-center">
            <h6>
              <strong>Select Account Type</strong>{" "}
            </h6>
            <div className="signin__selectUser">
              <Form.Check
                onChange={handleAccount}
                checked={!accountType}
                inline
                label="Client"
                type="radio"
                id="user"
                name="user"
              />
              <Form.Check
                onChange={handleAccount}
                checked={accountType}
                inline
                label="Clinic"
                type="radio"
                id="clinic"
                name="user"
              />
            </div>
          </div>
          <InputGroup controlid="formBasicEmail" className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text style={{ width: "100px" }} id="basic-addon1">
                Email
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              value={state.email}
              onChange={handleChange}
              name="email"
              ref={register({ required: true })}
              type="text"
              placeholder="Enter email"
            />
          </InputGroup>
          {errors.email && (
            <span className="text-danger mb-2">This field is required</span>
          )}
          <InputGroup controlid="formBasicPassword" className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text style={{ width: "100px" }} id="basic-addon1">
                Password
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              value={state.password}
              onChange={handleChange}
              name="password"
              ref={register({ required: true })}
              type="password"
              placeholder="Enter password"
            />
          </InputGroup>
          {errors.email && (
            <span className="text-danger mb-5">This field is required</span>
          )}
          <Button
            disabled={loading}
            type="submit"
            className="w-100 primary-btn submitbtn mt-5"
          >
            {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Submit
          </Button>
          <div className="signin-up-alts">
            <p>Don't have an account?</p>
            <a href="/signup" className="d-block danger-link">
              Sign up Here
            </a>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Signin;
