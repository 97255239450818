import { useState } from "react";
import { uploadPhoto } from "../../api";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";

function UploadPhotos() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [upStatus, setUpStatus] = useState(null);

  const submitForm = async (event) => {
    try {
      event.preventDefault();
      await uploadPhoto(selectedFile, "cdav49719@gmail.com");
      setUpStatus("Success!");
    } catch {
      setUpStatus("Unable to upload Photo");
    } finally {
      setTimeout(() => {
        setUpStatus(null);
      }, 3000);
    }
  };

  const onSelectFile = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <div className="App">
      {upStatus && <p>{upStatus}</p>}
      <form onSubmit={submitForm}>
        <input className="btn btn-outline-primary" type="file" name="photo" onChange={onSelectFile} />
        <Button variant="secondary" type="submit">
          Submit
        </Button>
      </form>
    </div>
  );
}

export default UploadPhotos;
