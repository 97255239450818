import React, { useEffect, useState } from "react";
import { allAccounts } from "../api";
import Ads from "../components/ads/Ads";
import Friends from "../components/friends/friends";
import Posts from "../components/posts/Posts";
import "../css/home.css";

function Home() {
  const [state, setState] = useState([]);
  const [account, setAccount] = useState(localStorage.getItem("account"));

  useEffect(() => {
    async function getUsers() {
      setState(await allAccounts());
    }

    getUsers();
  }, [state]);
  console.log(state);

  return (
    <>
      {account ? (
        account === "Client" ? (
          <div class="row">
            <div class="column left">
              <h3>Friends </h3>
              <Friends />
            </div>
            <div class="column middle">
              <Posts />
            </div>
            <div class="column right">
              <Ads />
            </div>
          </div>
        ) : (
          <div>
            <h3>Under Development</h3>
          </div>
        )
      ) : (
        <div>
          <h3>Please Sign in to get connected</h3>
          <div class="column right">
            <Ads />
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
