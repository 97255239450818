import Navigation from "./components/navigation/Navigation";
import Footer from "./components/navigation/Footer";
import Page404 from "./pages/Page404";
import { routes } from "./routes/routes";
import { Route, Switch, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";

function App() {
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    async function checkAuth() {
      if (localStorage.getItem("token")) {
        setAuth(true);
      } else {
        setAuth(false);
      }
    }
    checkAuth();
  });

  return (
    <div className="App">
      <ToastContainer />
      <Navigation auth={auth} />
      <div className="app__container">
        <Switch>
          {routes &&
            routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={route.Component}
              />
            ))}
          <Route path="*" component={Page404} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
}
export default withRouter(App);
